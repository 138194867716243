export interface ModalContent {
  title: string;
  message: string;
  actions: ModalActions;
  type: ModalType;
  route?: string;
  fileName?: string;
}

export interface ModalActions {
  primaryAction: string;
  secondaryAction: string;
}

export enum ModalType {
  WARNING = 'warning',
  CONFIRMATION = 'confirmation',
  DELETION = 'deletion',
  RENAMING = 'renaming',
}
