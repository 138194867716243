import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { validate as validateUuid } from 'uuid';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { HttpErrorResponse } from '@angular/common/http';

import { ResultUnitListService } from '../../result-unit-list/result-unit-list-page/result-unit-list.service';
import { TranslocoHttpLoader } from 'src/app/transloco/transloco.service';
import { PatientSearchService } from './patient-search.service';
import { AuthenticationService } from 'src/app/core/services';
import { SubmissionService } from '../submission-page/submission-page.service';

import { IPatient } from '../../result-unit-list/components/patient-card/patient.interface';
import { ESubmissionMode } from '../submission-page/submission.interface';
import CountriesCodeIso2 from '../../../../assets/countries';
import { AblyService } from 'src/app/core/services/ably.service';

@Component({
  selector: 'ztp-patient-search-page',
  templateUrl: './patient-search-page.component.html',
  styleUrls: ['./patient-search-page.component.scss'],
})
export class PatientSearchPageComponent implements OnInit, OnDestroy {
  private userSub: Subscription;

  private searchSub: Subscription;

  private labTechnicianId: string;

  zovuInput: FormControl;

  translationSubscription: Subscription;

  selectedCountryCodeIso2: string;

  cameraPermission = false;

  cameraPermissionMsg: string;

  showLoader = false;

  showIdError = false;

  showIdServerError = false;

  showScanError = false;

  showScanServerError = false;

  africaCountriesCodeIso2 = [];

  allowedFormats = [BarcodeFormat.QR_CODE];

  @ViewChild('Selecter') selectBox: NgSelectComponent;

  @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent;

  constructor(
    private labResultService: ResultUnitListService,
    private submissionService: SubmissionService,
    private authService: AuthenticationService,
    private patientSearchService: PatientSearchService,
    private translocoService: TranslocoHttpLoader,
    private router: Router,
    private readonly ablyNotificationService: AblyService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.zovuInput = new FormControl('', [
      Validators.required,
      Validators.pattern(/^(\d{3}\s){2}\d{3}$/),
    ]);
    this.labResultService.clearCurrentLabResult();
    this.userSub = this.authService.getCurrentLabTechnician().subscribe((labTechnician) => {
      this.selectedCountryCodeIso2 = labTechnician?.countryCodeISO2;
      this.labTechnicianId = labTechnician?.id;
    });
    this.africaCountriesCodeIso2 = CountriesCodeIso2.africa;
  }

  public initTranslations(): void {
    this.translationSubscription = this.translocoService
      .getTranslation()
      .subscribe((data) => {
        this.cameraPermissionMsg =
          data['retrieving_account']['camera_permission'];
      });
  }

  public openSelectBox(): void {
    this.selectBox.open();
    this.showIdError = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public scan(scanValue: string, _option: string): void {
    this.showScanError = false;
    this.searchGuid(scanValue);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public submit(_option: string): void {
    this.searchZovuId(this.zovuInput.value);
  }

  public hasPermission(event: boolean): void {
    if (!event) {
      alert(this.cameraPermissionMsg ?? '');
    }
    this.cameraPermission = event;
  }

  public onKeyDown(e): void {
    switch (e.keyCode) {
    case 13:
      this.searchZovuId(e.target.value);
      break;

    case 37:
    case 38:
    case 39:
    case 40:
      break;

    default:
      // eslint-disable-next-line no-case-declarations
      const trimmedValue = e.target.value.replace(/\s+/g, '');
      this.zovuInput.setValue(
        trimmedValue
          .split(/(\d{3})/)
          .filter(Boolean)
          .join(' ')
          .trim()
      );
      break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onInputValueChange(_evt): void {
    this.showIdError = false;
    this.showIdServerError = false;
    this.showScanError = false;
    this.showScanServerError = false;
  }

  private searchZovuId(id: string): void {
    this.zovuInput.disable();
    const zovuID = `${this.selectedCountryCodeIso2}-${id?.replace(/\s+/g, '')}`;
    if (/^([A-Z][A-Z])-(\d{9})$/.test(zovuID)) {
      this.searchSub = this.patientSearchService
        .getPatientByZovuId(zovuID)
        .subscribe({
          next: (patient: IPatient) => {
            if (patient) {
              this.ablyNotificationService.subscribeToSubmissionNotifications(this.labTechnicianId);
              this.patientSearchService.saveCurrentPatient(patient);
              this.submissionService.setSubmissionMode(ESubmissionMode.NEW_SUBMISSON);
              this.showLoader = true;
              setTimeout(() => {
                this.router.navigateByUrl('/submission');
              }, 1000);
            }
            this.zovuInput.enable();
          },
          error: (e) => {
            if (e instanceof HttpErrorResponse && e.status === 404) {
              this.showIdError = true;
            } else {this.showIdServerError = true;}
            this.zovuInput.enable();
          },
        });
    } else {
      console.log('INVALID ZOVU ID');
    }
  }

  private searchGuid(guid: string): void {
    this.zovuInput.disable();
    if (validateUuid(guid)) {
      this.searchSub = this.patientSearchService
        .getPatientByGuid(guid)
        .subscribe({
          next: (patient: IPatient) => {
            if (patient) {
              this.ablyNotificationService.subscribeToSubmissionNotifications(this.labTechnicianId);
              this.patientSearchService.saveCurrentPatient(patient);
              this.submissionService.setSubmissionMode(ESubmissionMode.NEW_SUBMISSON);
              this.showLoader = true;
              setTimeout(() => {
                this.router.navigateByUrl('/submission');
              }, 1000);
            } else {
              this.showScanError = true;
            }
            this.zovuInput.enable();
          },
          error: (e) => {
            if (e?.status === 404) {
              this.showScanError = true;
            } else {this.showScanServerError = true;}
            this.zovuInput.enable();
          },
        });
    } else {
      console.log('INVALID GUID SCANNED');
    }
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
    this.userSub?.unsubscribe();
    this.translationSubscription?.unsubscribe();
  }
}
