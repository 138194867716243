import { ILabCenter } from 'src/app/core/models/lab-center.interface';
import { Submission } from 'src/app/features/result-unit-upload/submission-page/submission.interface';
import { ITechnician } from 'src/app/core/models/technician.interface';
import { IPatient } from '../patient-card/patient.interface';

export enum EApprovalStatus {
  APPROVED = 'approved',
  UNAPPROVED = 'unapproved',
  CANCELLED = 'cancelled'
}
interface IApproval {
  status: EApprovalStatus;
  dateTime: number;
  approver: {
    id: string;
    givenName: string;
    surname: string;
  };
}

export class ResultUnit {
  id: string;
  idAlias: number;
  creationDateTime: number;
  labCenter: ILabCenter;
  technician: ITechnician;
  patient: IPatient;
  submission: Submission;
  approval?: IApproval;

  constructor() { }
}
