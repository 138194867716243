<header *ngIf="loggedIn && currentRoute !== '/notfound'">
  <nav>
  <a class="logo" routerLink="/home" target="_top"
    ><img alt="logo" src="/assets/images/logo/logo.svg"
  /></a>
  <ztp-env-badge class="env"></ztp-env-badge>
    <ul class="nav-buttons">
      <li class="nav-button" *ngIf="labTechnician?.role == 'lab-head'" routerLink="/dashboard" routerLinkActive="nav-button-active">
        <svg-icon key="dashboard"></svg-icon>
        <span> {{ 'navigation_bar.dashboard' | transloco }} </span>
      </li>
      <li class="nav-button"  routerLink="/home" routerLinkActive="nav-button-active">
        <svg-icon key="home"></svg-icon>
        <span> {{ 'navigation_bar.results' | transloco }} </span>
      </li>
      <li class="nav-button"  routerLink="/upload" routerLinkActive="nav-button-active">
        <svg-icon key="upload"></svg-icon>
        <span>{{ 'navigation_bar.upload' | transloco }}</span>
      </li>
      <li class="nav-button notification-bell" (click)="toggleDrawer()">
        <svg-icon class="notification-bell-icon" key="bell"></svg-icon>
        <div class="notification-counts" *ngIf="badgeCounter>0">
            {{badgeCounter}}
        </div>
      </li>
      <li class="nav-dropdown" (ztpClickOutside)="onClickOustide()">
        <label (click)="toggleNavMenu()">
          <span class="avatar">
            <span>{{ labTechnician?.givenName | slice: 0:1}}{{ labTechnician?.surname | slice: 0:1 }}</span>
          </span>
          <svg-icon key="chevron-down" fontSize="15px"></svg-icon>
        </label>
        <div *ngIf="showNavMenu" class="nav-dropdown-menu">
            <ul class="nav-dropdown-menu-list">
              <li class="nav-dropdown-menu-list-item">
                <span class="avatar">
                  <span>{{labTechnician?.givenName | slice: 0:1}}{{labTechnician?.surname | slice: 0:1}}</span>
                </span>
                <span class="account-info">
                  <span class="username">{{labTechnician?.givenName}}&nbsp;{{labTechnician?.surname}}</span>
                  <span class="role">{{labTechnician?.role | transloco}}</span>
                </span>
              </li>
              <li class="nav-dropdown-menu-list-item">
                <svg-icon key="help-circle"></svg-icon>
                <span> {{ 'navigation_bar.help_contact' | transloco }} </span>
              </li>
              <li class="nav-dropdown-menu-list-item" (click)="logUserOut()">
                <svg-icon fontSize="22px" key="exit"></svg-icon>
                <span> {{ 'navigation_bar.logout' | transloco }} </span>
              </li>
            </ul>
        </div>
      </li>
    </ul>
  </nav>
</header>
