import { AccountInfo } from '@azure/msal-browser';
import { TokenClaims } from '@azure/msal-common';

export interface ITechnician {
  id: string;
  givenName: string;
  surname: string;
  city: string;
  country: string;
  role: ERole | string;
  labCenterAlias: string;
  labCenterId: string;
  countryCodeISO2: string;
}

export enum ERole {
  LAB_HEAD = 'lab-head',
  LAB_TECHNICIAN = 'lab-technician',
}

export class Technician {
  id: string;
  givenName: string;
  surname: string;
  // mail: string;
  city: string;
  country: string;
  role: ERole | string;
  labCenterAlias: string;
  labCenterId: string;
  countryCodeISO2: string;

  constructor(activeAccount: AccountInfo) {
    const tokenClaims = activeAccount?.idTokenClaims as TokenClaims & {
      [key: string]: string;
    };
    this.id = tokenClaims?.oid;
    this.givenName = tokenClaims?.given_name;
    this.surname = tokenClaims?.family_name;
    this.city = tokenClaims?.city;
    this.country = tokenClaims?.country;
    // this.mail = tokenClaims?.emails[0];
    this.role = tokenClaims?.extension_Role;
    this.labCenterAlias = tokenClaims?.extension_AssignedLabCenterAlias;
    this.labCenterId = tokenClaims?.extension_AssignedLabCenterId;
    this.countryCodeISO2 = tokenClaims?.extension_CountryCodeISO2;
  }
}
