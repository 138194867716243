/* istanbul ignore file */
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription, tap, switchMap, takeUntil, Subject } from 'rxjs';

import { AppInsightsService, AuthenticationService } from './core/services';
import { AdvanceSearchService } from './features/advance-search/advance-search/advance-search.service';
import { SubmissionService } from './features/result-unit-upload/submission-page/submission-page.service';

import { ISubmissionNotification } from './core/components/notification-drawer/notification-tile/notification.interface';
import { UserIdleService } from 'angular-user-idle';
import { AblyService } from './core/services/ably.service';

@Component({
  selector: 'ztp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private authSub: Subscription;
  private userSub: Subscription;
  private socketSub: Subscription;
  private destroy$ = new Subject<void>();

  constructor(
    public appInsightService: AppInsightsService,
    public authService: AuthenticationService,
    private submissionService: SubmissionService,
    private labSearchService: AdvanceSearchService,
    private userIdleActivity: UserIdleService,
    private readonly ablyService: AblyService
  ) {}

  title = 'CodeSandbox';
  hideDrawer = false;

  toggle() {
    this.hideDrawer = !this.hideDrawer;
  }

  onClose() {
    this.hideDrawer = true;
  }

  @HostListener('window:beforeunload', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeUnloadHandler(event) {
    if (event.target.location.pathname === '/dashboard') {
      this.ablyService.disconnectAllEventSources();
      return;
    }
    if (
      event.target.location.pathname !== '/signin' &&
      event.currentTarget.location.hash === ''
    ) {
      this.ablyService.disconnectAllEventSources();
      sessionStorage.clear();
      localStorage.clear();
    }
  }

  ngOnInit(): void {
    this.appInsightService.loadAppInsights();

    this.authService
      .handleUserLoginProcess()
      .pipe(
        tap(() => this.handleUserIdleActivity()),
        switchMap((labTechnician) =>
          this.ablyService
            .subscribeToSubmissionNotifications(labTechnician?.id)
            .pipe(
              tap((submission) => {
                this.appInsightService.log(
                  `Submission notification received, ${JSON.stringify(
                    submission
                  )}`
                );
                const submissionNotification = JSON.parse(
                  JSON.parse(submission['data'])['data']
                ) as ISubmissionNotification;
                if (
                  submissionNotification?.technicianId === labTechnician?.id
                ) {
                  this.submissionService.updateSubmissionDraft(
                    submissionNotification?.submissionId,
                    true
                  );
                  this.labSearchService
                    .refreshLabResults(labTechnician)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe();
                }
              }),
              takeUntil(this.destroy$)
            )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handleUserIdleActivity(): void {
    this.userIdleActivity.startWatching();
    this.userIdleActivity.onTimerStart().subscribe();
    this.userIdleActivity.onTimeout().subscribe(() => {
      this.appInsightService.log('User was idle => logging out');
      this.ablyService.disconnectAllEventSources();
      this.authService.logout();
    });
  }

  onUserActivity() {
    this.userIdleActivity.resetTimer();
  }

  ngOnDestroy() {
    this.authSub?.unsubscribe();
    this.userSub?.unsubscribe();
    this.socketSub?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
