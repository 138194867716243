<div *ngIf="_labResult" class="result-unit-preview">
  <div *ngIf="showActionMenu" class="action-menu" (ztpClickOutside)="onClickOustide()">
    <div class="edit" (click)="showEditConfirmationModal()">
      <svg-icon fontSize="15px" key="edit-action"></svg-icon>
      <span>{{ "result_list.result_summary_pannel.action.edit" | transloco }}</span>
    </div>
    <ng-template [ngIf]="labTechnician?.role === 'lab-head'" >
      <div 
      [ngClass]="{'approve': true, 'disable': labResultApproved}"
      (click)="!labResultApproved && saveApproval()"
      >
        <svg-icon fontSize="13px" key="approve-action"></svg-icon>
        <span>{{ "result_list.result_summary_pannel.action.approve" | transloco }}</span>
      </div>
      <div 
      [ngClass]="{'unapprove': true, 'disable': !labResultApproved}"
      (click)="labResultApproved && showUnapproveConfirmationModal()"
      >
        <svg-icon fontSize="13px" key="unapprove-action"></svg-icon>
        <span>{{ "result_list.result_summary_pannel.action.unapprove" | transloco }}</span>
    </div>
    </ng-template>
  </div>
  <div class="result-unit-preview-header">
    <div class="statuses">
      <span class="title">{{ "result_list.result_summary_pannel.result_title" | transloco }}</span>
      <div *ngIf="showPlaceHolder; else showPills" class="pill placeholder"><span class="content"></span></div>
      <ng-template #showPills >
        <div class="pills">
          <span class="id">
            <span>{{_labResult?.idAlias}}</span>
          </span>
          <span class="badge approve" *ngIf="labResultApproved">
            <svg-icon key="check" fontSize='18px'></svg-icon>
            <span>{{ "result_list.approved_marker" | transloco }}</span>
          </span>
          <!-- <span class="badge unapprove" *ngIf="labResult?.approval?.status === 'unapproved'">
            <svg-icon key="unapprove-action" fontSize='18px'></svg-icon>
            <span>Unapproved</span>
          </span> -->
        </div>
      </ng-template>
      <label *ngIf="!showPlaceHolder" class="actions" (click)="toggleActionMenu()">
        <svg-icon key="dot-menu"></svg-icon>
      </label>
    </div>
    <div class="info">
      <div *ngIf="showPlaceHolder; else showResult" class="result">
        <div class="placeholder inline"><span class="content"></span></div>
        <div class="placeholder inline"><span class="content"></span></div>
        <div class="placeholder inline"><span class="content"></span></div>
      </div>
      <ng-template #showResult>
        <div class="result">
          <span class="name">{{_labResult?.submission.category.studyName}}</span>
          <span class="department">{{_labResult?.submission.category.labDepartment}}</span>
          <span class="date">{{_labResult?.submission.submissionDateTime | zovuFullDateFormat}}</span>
        </div>
      </ng-template>

      <div *ngIf="showPlaceHolder; else showAuthor" class="author">
        <div class="placeholder option"><span class="content"></span></div>
        <div class="placeholder option"><span class="content"></span></div>
      </div>
      <ng-template #showAuthor>
        <div class="author">
          <span>{{ "result_list.result_summary_pannel.upload_by" | transloco }}</span>
          <span class="name">{{_labResult?.submission.updateBy.givenName}}&nbsp;{{_labResult?.submission.updateBy.surname}}</span>
        </div>
      </ng-template>
    </div>
  </div>
  <span class="separator"></span>
  <div class="result-unit-preview-body">
    <div class="reports">
      <h3 class="title">{{ "result_list.result_summary_pannel.report_title" | transloco }}</h3>
      <div *ngIf="showPlaceHolder; else showReport" class="value">
        <div class="cover">
          <svg-icon key="pdf" fontSize="55px"></svg-icon>
        </div>
        <div class="preview">
          <div  class="placeholder image-line"><span class="content"></span></div>
          <div  class="placeholder image-line"><span class="content"></span></div>
          <div  class="placeholder image-line"><span class="content"></span></div>
        </div>
      </div>
      <ng-template #showReport>
        <div class="value">
          <div class="cover">
            <svg-icon key="pdf" fontSize="55px"></svg-icon>
          </div>
          <div class="preview">
            <span class="name">{{_labResult?.submission.category.studyName}}</span>
            <span class="pages">{{_labResult?.submission.media.pagesCount}}{{_labResult?.submission.media.pagesCount > 1 ? ' pages' : ' page'}}</span>
            <span (click)="openPreview(_labResult)" class="button">{{ "result_list.result_summary_pannel.preview_report" | transloco }}</span>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="comments" *ngIf="_labResult?.submission.comments">
      <h3 class="title">{{ "result_list.result_summary_pannel.comment_title" | transloco }}</h3>
      <div *ngIf="showPlaceHolder; else showComment" class="comment placeholder"><span class="content"></span></div>
      <ng-template #showComment>
        <p class="value">
          {{_labResult?.submission.comments}}
        </p>
      </ng-template>
    </div>
  </div>
</div>