<div class="modal modal-visible" id="modal" #modal class="modal modal-visible" role="dialog" aria-hidden="true"  *transloco="let t; read: 'print_action'">
    <div class="modal-dialog disable-selection" (ztpClickOutside)="closeModal()">
        <div class="header">
            <div class="infos">
                <span class="name">{{name}}</span>
                <span class="page">{{pages && docPagesCounterMessage(pages)}}</span>
            </div>
            <div class="zoom" *ngIf="fileType === 'application/pdf' && !isLoading">
                <span class="decrease" (click)="zoomOut()">-</span>
                <span class="label">Zoom</span>
                <span class="increase" (click)="zoomIn()">+</span>
            </div>
            <svg-icon key="close-modal" fontSize="20px" (click)="closeModal()"></svg-icon>
        </div>
        <div class="content">
            <div *ngIf="isLoading; else showContent" class="sp-container">
                <div class="spinner"></div>
                <span>Loading pdf...</span>
            </div>
            <ng-template #showContent>
                <pdf-viewer
                    [src]="fileUrl"
                    [render-text]="false"
                    *ngIf="fileType === 'application/pdf'; else isImage"
                    [original-size]="false"
                    [fit-to-page]="false"
                    [zoom]="zoom"
                    class="fallback-reader"
                >
                </pdf-viewer>
            </ng-template>
            <ng-template #isImage>
                <img [src]="fileUrl" alt="" >
            </ng-template>
            <div class="footer" *ngIf="!isLoading">
              <button class="print-button button-hover" (click)="openModal()">
                <span class="print-text">{{ t('print') }}</span>
                <svg-icon key="printPdf" class="svg-icon"></svg-icon>
              </button>
            </div>
        </div>
    </div>
