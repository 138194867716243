import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ZXingScannerComponent, ZXingScannerModule } from '@zxing/ngx-scanner';
import { SvgIconsModule } from '@ngneat/svg-icon';

import { FileUploadModule } from 'ng2-file-upload';
import { DragulaModule } from 'ng2-dragula';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';

import { PatientSearchService } from './patient-search-page/patient-search.service';
import { SubmissionService } from './submission-page/submission-page.service';

import { PatientSearchPageComponent } from './patient-search-page/patient-search-page.component';
import { SubmissionPageComponent } from './submission-page/submission-page.component';

import {
  CommentCardComponent,
  FilePreviewModalComponent,
  LoaderComponent,
  PatientCardComponent,
  StudyNameSelectorComponent,
  UploadCardComponent,
} from './components';

import { appErrorIcon } from '../../svg/zovu/error';
import { appChevronDownIcon } from '../../svg/zovu/chevron-down';
import { appCalendarIcon } from 'src/app/svg/zovu/calendar';
import { appPersonIcon } from 'src/app/svg/zovu/person';
import { appCameraOffIcon } from 'src/app/svg/zovu/camera-off';
import { appEditInfoIcon } from 'src/app/svg/zovu/edit-info';
import { appWarningIcon } from 'src/app/svg/zovu/warning';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ZXingScannerModule,
    SvgIconsModule.forChild([
      appErrorIcon,
      appChevronDownIcon,
      appCalendarIcon,
      appPersonIcon,
      appCameraOffIcon,
      appEditInfoIcon,
      appWarningIcon,
    ]),
    FileUploadModule,
    PdfViewerModule,
    TranslocoRootModule,
    SharedModule,
    DragulaModule.forRoot(),
  ],
  declarations: [
    PatientSearchPageComponent,
    SubmissionPageComponent,
    CommentCardComponent,
    FilePreviewModalComponent,
    PatientCardComponent,
    StudyNameSelectorComponent,
    UploadCardComponent,
    LoaderComponent,
  ],
  providers: [ZXingScannerComponent, PatientSearchService, SubmissionService],
  exports: [PatientSearchPageComponent, SubmissionPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ResultUnitUploadModule {}
