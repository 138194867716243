import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ModalDomService } from './modal.dom.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  private modalActionsSubject = new BehaviorSubject<any>(null);

  public modalActions = this.modalActionsSubject.asObservable();

  constructor(private modalDomService: ModalDomService) { }

  init(component: any, inputs: object, outputs?: object, callback?: any) {
    const componentConfig = { inputs, outputs, callback };
    this.modalDomService.appendComponentToBody(component, componentConfig);
  }

  destroy() {
    this.modalDomService.removeComponent();
  }

  emitAction(value: any) {
    this.modalActionsSubject.next(value);
  }
}

