import { AfterViewInit, Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';
import { filter } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ModalService } from '../../../shared/modal/modal.service';
import { PatientSearchService } from '../patient-search-page/patient-search.service';
import { TranslocoHttpLoader } from 'src/app/transloco/transloco.service';
import { ToasterService } from 'src/app/core/components/toaster/toaster.service';
import { SubmissionService } from './submission-page.service';
import { AuthenticationService, LabCenterService } from 'src/app/core/services';
import { ResultUnitListService } from '../../result-unit-list/result-unit-list-page/result-unit-list.service';
import { dbService } from 'src/app/core/services/db.service';

import { ModalComponent } from '../../../shared/modal/modal.component';
import { FilePreviewModalComponent } from 'src/app/features/result-unit-upload/components/file-preview-modal/file-preview-modal.component';
import { IReport } from 'src/app/features/result-unit-upload/components/upload-card/upload-card.component';

import { ModalContent, ModalType } from '../../../shared/modal/modal.interface';
import { IPatient } from '../../result-unit-list/components/patient-card/patient.interface';
import { EMetaType, ESubmissionMode, SubmissionDraft } from 'src/app/features/result-unit-upload/submission-page/submission.interface';
import { ResultUnit } from '../../result-unit-list/components/result-unit-card/result-unit.interface';
import { ILabCenter, ITechnician } from 'src/app/core/models';
import { EToastStatus, IToast } from 'src/app/core/components/toaster/toast-tile/toast-tile.interface';

@Component({
  selector: 'ztp-submission-page',
  templateUrl: './submission-page.component.html',
  styleUrls: ['./submission-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmissionPageComponent implements OnInit, OnDestroy, AfterViewInit {
  get comments(): AbstractControl {
    return this.submissionForm.get('comments');
  }

  get uploadReport(): AbstractControl {
    return this.submissionForm.get('uploadReport');
  }

  get labTestName(): AbstractControl {
    return this.submissionForm.get('labTestName');
  }

  get labDepartment(): AbstractControl {
    return this.submissionForm.get('labDepartment');
  }

  private uploadPageNavigationTimeOut: any;

  private modalContent: ModalContent;

  private cancelModalContent: ModalContent;

  private deleteReportModalContent: ModalContent;

  private modalSubscription: Subscription;

  private authSub: Subscription;

  private patientSearchSub: Subscription;

  private labCenterSub: Subscription;

  private submissionSub: Subscription;

  private toasterSub: Subscription;

  currentPatient: IPatient;

  currentLabTechnician: ITechnician;

  currentLabCenter: ILabCenter;

  currentLabResult: ResultUnit;

  submissionMode: ESubmissionMode;

  submissionDraft: SubmissionDraft;

  submissionForm: FormGroup;

  fileUploader: FileUploader;

  currentSubmissionReport: IReport;

  currentSubmissionId: string;

  currentSubmissionEditVersion: number;

  currentSubmissionDateTime: number;

  canEditLabResult = false;

  isResultUnitReportDeleted = false;

  isReportFilesInvalid: boolean;

  isFilesCompressInProgress: boolean;

  isSubmiting = false;

  dataLiveLoadingCheck = {
    labTestName: {
      hasData: false,
      hasFocus: false
    },
    uploadReport: {
      hasData: false,
      hasFocus: false
    },
    comments: {
      hasData: false,
      hasFocus: false
    }
  };

  constructor(
    private modalService: ModalService,
    private authService: AuthenticationService,
    private patientSearchService: PatientSearchService,
    private labCenterService: LabCenterService,
    private submissionService: SubmissionService,
    private resultListService: ResultUnitListService,
    private translocoService: TranslocoHttpLoader,
    private router: Router,
    private toasterService: ToasterService,
    private _location: Location
  ) {
    this.submissionForm = new FormGroup({
      comments: new FormControl(''),
      uploadReport: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
      ]),
      labTestName: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
      labDepartment: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
  }

  ngOnInit(): void {
    this.submissionSub = this.submissionService.getSubmissionMode().subscribe((submissionMode) => {
      this.submissionMode = submissionMode;
    });
    this.submissionSub = this.submissionService.getCurrentLabResult()
      .subscribe((currentLabResult) => {
        this.currentLabResult = currentLabResult;
        if (this.submissionMode !== ESubmissionMode.NEW_SUBMISSON) {
          this.labTestName.setValue(currentLabResult?.submission.category.studyName);
        }
      });
    this.loadTranslation();
    this.modalSubscription = this.modalService.modalActions
      .pipe(filter((data) => data !== null))
      .subscribe((action) => {
        this.runSubmitModalActionCallback(action);
      });
  }

  ngAfterViewInit() {
    this.submissionForm.valueChanges.subscribe(val => {
      this.dataLiveLoadingCheck.labTestName.hasData = !!val?.labTestName;
      this.dataLiveLoadingCheck.comments.hasData = !!val?.comments;
    });

    this.patientSearchSub = this.patientSearchService.getCurrentPatient()
      .subscribe((currentPatient) => this.currentPatient = currentPatient);

    this.authSub = this.authService.getCurrentLabTechnician()
      .subscribe((currentLabTechnician) => this.currentLabTechnician = currentLabTechnician);

    this.labCenterSub = this.labCenterService.getCurrentLabCenter()
      .subscribe((currentLabCenter) => this.currentLabCenter = currentLabCenter);

    this.loadSubmissionData();
  }

  public loadSubmissionData(): void {
    if (this.submissionMode !== ESubmissionMode.NEW_SUBMISSON) {
      this.labDepartment.setValue(this.currentLabResult?.submission.category.labDepartment);
      this.comments.setValue(this.currentLabResult?.submission.comments);
    }
    if (this.submissionMode === ESubmissionMode.RE_UPLOAD_SUBMISSON) {
      this.submissionService.deleteSubmissionDraft(this.currentLabResult?.submission.id);
    }
    if (this.submissionMode === ESubmissionMode.EDIT_SUBMISSON) {
      //download document and set current report
    }
  }

  public loadTranslation(): void {
    this.modalSubscription = this.translocoService.getTranslation().subscribe(data => {
      this.modalContent = {
        message: data['modal_submission']['message'],
        title: 'Confirmation',
        route: '',
        actions: {
          primaryAction: data['modal_submission']['primary_action'],
          secondaryAction: data['modal_submission']['secondary_action'],
        },
        type: ModalType.CONFIRMATION,
      };
      this.deleteReportModalContent = {
        title: 'Confirmation',
        type: ModalType.WARNING,
        actions: {
          primaryAction: data['modal_report_deletion']['primary_action'],
          secondaryAction: data['modal_report_deletion']['secondary_action']
        },
        message: data['modal_report_deletion']['message'],
        route: ''
      };
      this.cancelModalContent = {
        title: 'Confirmation',
        type: ModalType.WARNING,
        actions: {
          primaryAction: data['modal_cancel']['primary_action'],
          secondaryAction: data['modal_cancel']['secondary_action']
        },
        message: data['modal_cancel']['message'],
        route: ''
      };
    });
  }

  private runSubmitModalActionCallback(action: string): void {
    switch (action) {
    case 'Continue':
    case 'Continuer':
      this.submitLabResult();
      break;

    case 'Cancel':
    case 'Annuler':
    case 'No':
    case 'Non':
      this.abortSubmission();
      break;

    case 'Delete':
    case 'Effacer':
      this.removeResultUnitReports();
      break;

    case 'Yes':
    case 'Oui':
      this.cancelSubmission();
      break;
    }
  }

  public submitLabResult(): void {
    this.isSubmiting = true;
    this.currentSubmissionDateTime = Date.now();
    switch (this.submissionMode) {
    case ESubmissionMode.NEW_SUBMISSON:
      this.currentSubmissionId = uuidV4();
      this.currentSubmissionEditVersion = 0;
      this.createLabResult();
      break;
    case ESubmissionMode.EDIT_SUBMISSON:
      this.currentSubmissionId = this.currentLabResult.submission.id;
      this.currentSubmissionEditVersion = this.currentLabResult.submission.meta.editVersion + 1;
      this.editLabResult();
      break;
    case ESubmissionMode.RE_UPLOAD_SUBMISSON:
      this.currentSubmissionId = uuidV4();
      this.currentSubmissionEditVersion = 0;
      this.reCreateLabResult();
      break;

    default:
      break;
    }
  }

  public async createLabResult(): Promise<void> {
    let resultUnit = new ResultUnit();
    resultUnit.creationDateTime = this.currentSubmissionDateTime;
    resultUnit.id = uuidV4();
    resultUnit.idAlias = 0;
    resultUnit.patient = this.currentPatient;
    resultUnit.technician = this.currentLabTechnician;
    resultUnit.labCenter = this.currentLabCenter;
    resultUnit.submission = {
      id: this.currentSubmissionId,
      meta: {
        type: this.getReportType(this.currentSubmissionReport.fileExtension),
        editVersion: this.currentSubmissionEditVersion,
      },
      category: {
        labDepartment: this.labDepartment.value,
        studyName: this.labTestName.value,
      },
      submissionDateTime: this.currentSubmissionDateTime,
      updateBy: this.currentLabTechnician,
      media: {
        mediaId: `${this.currentLabCenter.alias.toLowerCase()}_${this.getNameAsId(this.currentPatient.surname, this.currentPatient.givenName)}_${this.currentSubmissionId}_${this.currentSubmissionEditVersion}`,
        fileName: `${this.currentSubmissionId}.${this.currentSubmissionReport.fileExtension}`,
        rawFile: '',
        fileExtension: this.currentSubmissionReport.fileExtension,
        fileContentType: this.currentSubmissionReport.fileContentType,
        uploadDateTime: this.currentSubmissionDateTime,
        pagesCount: this.currentSubmissionReport.pagesCount,
      },
      comments: this.comments.value
    };

    delete resultUnit.labCenter['_id'];
    delete resultUnit.technician['countryCodeISO2'];
    delete resultUnit.submission.updateBy['countryCodeISO2'];
    let submissionDraft = new SubmissionDraft(resultUnit);
    submissionDraft.startTimer();
    this.submissionService.addSumissionDraft(submissionDraft);
    resultUnit.submission.media.rawFile = this.currentSubmissionReport.fileItem as string;
    await dbService.insertSubmissionDraftMedia(resultUnit.submission.id, resultUnit.submission.media.rawFile);
    this.initToast();
    this.router.navigate(['/upload']).then(() => {
      this.submissionService.addLabResult(this.currentLabTechnician.id, resultUnit).subscribe();
      this.isSubmiting = false;
    });
  }

  public async reCreateLabResult(): Promise<void> {
    let resultUnit = new ResultUnit();
    resultUnit.creationDateTime = this.currentSubmissionDateTime;
    resultUnit.id = this.currentLabResult.id;
    resultUnit.idAlias = 0;
    resultUnit.patient = this.currentPatient;
    resultUnit.technician = this.currentLabTechnician;
    resultUnit.labCenter = this.currentLabCenter;
    resultUnit.submission = {
      id: this.currentSubmissionId,
      meta: {
        type: this.getReportType(this.currentSubmissionReport.fileExtension),
        editVersion: this.currentSubmissionEditVersion,
      },
      category: {
        labDepartment: this.labDepartment.value,
        studyName: this.labTestName.value,
      },
      submissionDateTime: this.currentSubmissionDateTime,
      updateBy: this.currentLabTechnician,
      media: {
        mediaId: `${this.currentLabCenter.alias.toLowerCase()}_${this.getNameAsId(this.currentPatient.surname, this.currentPatient.givenName)}_${this.currentSubmissionId}_${this.currentSubmissionEditVersion}`,
        fileName: `${this.currentSubmissionId}.${this.currentSubmissionReport.fileExtension}`,
        rawFile: '',
        fileExtension: this.currentSubmissionReport.fileExtension,
        fileContentType: this.currentSubmissionReport.fileContentType,
        uploadDateTime: this.currentSubmissionDateTime,
        pagesCount: this.currentSubmissionReport.pagesCount,
      },
      comments: this.comments.value
    };

    delete resultUnit.labCenter['_id'];
    delete resultUnit.technician['countryCodeISO2'];
    delete resultUnit.submission.updateBy['countryCodeISO2'];
    let submissionDraft = new SubmissionDraft(resultUnit);
    submissionDraft.startTimer();
    this.submissionService.addSumissionDraft(submissionDraft);
    resultUnit.submission.media.rawFile = this.currentSubmissionReport.fileItem as string;
    await dbService.insertSubmissionDraftMedia(resultUnit.submission.id, resultUnit.submission.media.rawFile);
    this.initToast();
    this.router.navigate(['/upload']).then(() => {
      this.submissionService.addLabResult(this.currentLabTechnician.id, resultUnit).subscribe();
      this.isSubmiting = false;
    });
  }

  public async editLabResult(): Promise<void> {
    let resultUnit = new ResultUnit();
    resultUnit.creationDateTime = this.currentSubmissionDateTime;
    resultUnit.id = this.currentLabResult.id;
    resultUnit.idAlias = this.currentLabResult.idAlias;
    resultUnit.patient = this.currentPatient;
    resultUnit.technician = this.currentLabResult.technician;
    resultUnit.labCenter = this.currentLabCenter;
    resultUnit.submission = {
      id: this.currentSubmissionId,
      meta: {
        type: this.isResultUnitReportDeleted && this.currentSubmissionReport?.fileItem ?
          this.getReportType(this.currentSubmissionReport.fileExtension)
          : this.currentLabResult.submission.meta.type,
        editVersion: this.currentSubmissionEditVersion,
      },
      category: {
        labDepartment: this.labDepartment.value,
        studyName: this.labTestName.value,
      },
      submissionDateTime: this.currentSubmissionDateTime,
      updateBy: this.currentLabTechnician,
      media: this.isResultUnitReportDeleted && this.currentSubmissionReport?.fileItem ?
        {
          mediaId: `${this.currentLabCenter.alias.toLowerCase()}_${this.getNameAsId(this.currentPatient.surname, this.currentPatient.givenName)}_${this.currentSubmissionId}_${this.currentSubmissionEditVersion}`,
          fileName: `${this.currentSubmissionId}.${this.currentSubmissionReport.fileExtension}`,
          rawFile: '',
          fileExtension: this.currentSubmissionReport.fileExtension,
          fileContentType: this.currentSubmissionReport.fileContentType,
          uploadDateTime: this.currentSubmissionDateTime,
          pagesCount: this.currentSubmissionReport.pagesCount,
        }
        : { ...this.currentLabResult.submission.media, rawFile: '' },
      comments: this.comments.value
    };

    delete resultUnit.labCenter['_id'];
    delete resultUnit.technician['countryCodeISO2'];
    delete resultUnit.submission.updateBy['countryCodeISO2'];
    let submissionDraft = new SubmissionDraft(resultUnit);
    submissionDraft.startTimer();
    this.submissionService.addSumissionDraft(submissionDraft);
    if (this.isResultUnitReportDeleted && this.currentSubmissionReport?.fileItem) {
      resultUnit.submission.media.rawFile = this.currentSubmissionReport.fileItem as string;
    }
    await dbService.insertSubmissionDraftMedia(resultUnit.submission.id, resultUnit.submission.media.rawFile);
    this.initToast();
    this.router.navigate(['/home']).then(() => {
      this.submissionService.updateLabResult(this.currentLabTechnician.id, resultUnit).subscribe();
      this.isSubmiting = false;
    });
  }

  private initToast(): void {
    let toastData: IToast = {
      status: EToastStatus.IN_PROGRESS,
      patient: {
        givenName: this.currentPatient.givenName,
        surname: this.currentPatient.surname,
        zovuId: this.currentPatient.zovuId,
      },
      studyName: this.labTestName.value,
      media: this.submissionMode !== ESubmissionMode.EDIT_SUBMISSON ? {
        type: this.currentSubmissionReport.fileExtension,
        pagesCount: this.currentSubmissionReport.pagesCount
      } : (
        this.isResultUnitReportDeleted && this.currentSubmissionReport?.fileItem ?
          {
            type: this.currentSubmissionReport.fileExtension,
            pagesCount: this.currentSubmissionReport.pagesCount
          } :
          {
            type: this.currentLabResult.submission.media.fileExtension,
            pagesCount: this.currentLabResult.submission.media.pagesCount
          }
      )
    };
    this.toasterService.addToast(toastData);
  }

  public abortSubmission(): void {
    console.log('Submission aborted');
  }

  public cancelSubmission(): void {
    this.resultListService.clearCurrentLabResult();
    setTimeout(() => {
      this._location.back();
    }, 500);
  }

  public setTestName(selectValue: { labTestName: string; labDepartment: string }): void {
    this.submissionForm.controls['labTestName'].setValue(selectValue?.labTestName);
    this.submissionForm.controls['labDepartment'].setValue(selectValue?.labDepartment);
  }

  public setStudyListBoxOpenStatus(boxOpenStatus: boolean): void {
    this.dataLiveLoadingCheck.labTestName.hasFocus = boxOpenStatus;
  }

  public setFileBoxOpenStatus(boxOpenStatus: boolean): void {
    this.dataLiveLoadingCheck.uploadReport.hasFocus = boxOpenStatus;
  }

  public setCommentEditStatus(commentBoxFocusStatus: boolean): void {
    this.dataLiveLoadingCheck.comments.hasFocus = commentBoxFocusStatus;
  }

  public setReportValidity(error: boolean): void {
    this.isReportFilesInvalid = error;
  }

  public setFileCompressStatus(isCompressing: boolean) {
    this.isFilesCompressInProgress = isCompressing;
  }

  public saveReport(report: IReport) {
    this.currentSubmissionReport = report;
  }

  public saveFileQueue(uploader: FileUploader): void {
    this.fileUploader = uploader;
    // this.dataLiveLoadingCheck.uploadReport.hasData = uploader?.queue?.length > 0;
  }

  public submitModal(): void {
    const inputs = {
      modalContent: this.modalContent,
    };
    this.modalService.init(ModalComponent, inputs);
  }

  public cancelModal(): void {
    if (this.submissionMode === ESubmissionMode.EDIT_SUBMISSON &&
      (this.labTestName.value === this.currentLabResult.submission.category.studyName) &&
      !this.isResultUnitReportDeleted &&
      !this.submissionForm.dirty) {
      this.cancelSubmission();
    } else {
      const inputs = {
        modalContent: this.cancelModalContent,
      };
      this.modalService.init(ModalComponent, inputs);
    }
  }

  public showDeletionConfirmationModal(): void {
    const inputs = {
      modalContent: this.deleteReportModalContent,
    };
    this.modalService.init(ModalComponent, inputs);
  }

  openPreview(item: IReport) {
    item.fileName = `${this.currentSubmissionId}.${this.currentSubmissionReport.fileExtension}`;
    this.modalService.init(FilePreviewModalComponent, { fileItem: item, isReport: true });
  }

  reportPagesCounterMessage(n: number) {
    if (String(n).length == 1) {
      return `0${n} page${n > 1 ? 's' : ''}`;
    } else {
      return `${n} pages`;
    }
  }

  public getReportType(type: string): EMetaType {
    return {
      pdf: EMetaType.PDF,
      jpg: EMetaType.IMAGE,
      jpeg: EMetaType.IMAGE,
      png: EMetaType.IMAGE,
      form: EMetaType.FORM,
    }[type];
  }

  public getNameAsId(surname: string, givenName: string): string {
    return `${surname}-${givenName}`;
    // return `${surname} ${givenName}`.trim().match(/\b(\w+)\b/g).join('-');
  }

  private removeResultUnitReports(): void { //TODO: look at this
    this.canEditLabResult = true;
    this.isResultUnitReportDeleted = true;
  }

  ngOnDestroy(): void {
    this.modalSubscription?.unsubscribe();
    this.authSub?.unsubscribe();
    this.patientSearchSub?.unsubscribe();
    this.labCenterSub?.unsubscribe();
    this.submissionSub?.unsubscribe();
    this.toasterSub?.unsubscribe();
    clearTimeout(this.uploadPageNavigationTimeOut);
  }
}

//TODO: return back to the list with focused context after edit mode
