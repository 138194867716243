/* eslint-disable import/no-extraneous-dependencies */
/*istanbul ignore file */
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthenticationService } from '../../services';
import { NotificationDrawerService } from '../notification-drawer/notification-drawer.service';
import { SubmissionService } from 'src/app/features/result-unit-upload/submission-page/submission-page.service';

import { ITechnician } from '../../models/technician.interface';
import { ESubmissionStatus } from '../../../features/result-unit-upload/submission-page/submission.interface';

@Component({
  selector: 'ztp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() loggedIn: boolean;

  private authSub: Subscription;

  private failedLabSubmissionsSub: Subscription;

  badgeCounter = 0;

  showNavMenu: boolean;

  labTechnician: ITechnician;

  currentRoute: string;

  displayRole = {
    'lab-head': 'Lab Head',
    'lab-technician': 'Technician'
  };

  constructor(
    private auth: AuthenticationService,
    private submissionsService: SubmissionService,
    private router: Router,
    private drawerService: NotificationDrawerService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.getloggedUser();
    this.getBadgerCount();
  }

  getloggedUser(): void {
    this.authSub = this.auth.stateChanged
      .subscribe(() => {
        this.authSub = this.auth.getCurrentLabTechnician().subscribe((labTechnician) => {
          this.labTechnician = labTechnician;
        });
      });
  }

  getBadgerCount(): void {
    this.failedLabSubmissionsSub = this.submissionsService.stateChanged.subscribe((state) => {
      if (state && state.submissionDrafts) {
        const labSubs = Object.values(state.submissionDrafts).filter(
          (labSub) => labSub.submissionStatus === ESubmissionStatus.FAILED || labSub.submissionStatus === ESubmissionStatus.RE_UPLOAD
        );
        this.badgeCounter = labSubs.length;
      }
    });
  }

  logUserOut() {
    this.auth.logout();
  }

  toggleDrawer() {
    this.drawerService.togglePanel();
  }

  toggleNavMenu() {
    this.showNavMenu = !this.showNavMenu;
  }

  onClickOustide() {
    this.showNavMenu = false;
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.failedLabSubmissionsSub?.unsubscribe();
  }
}
